import React, { useState, useEffect, forwardRef, useRef} from 'react';
import '../../../resources/Keno/css/admin.css';
import { FaCheck, FaSignOutAlt, FaPrint, FaTimes, FaSearch, FaIconDashboard, FaExclamationCircle, FaShoppingCart, FaCoins } from 'react-icons/fa';


//import Navbar from '../navbars/Navbar';
const calendar_logo = require('../../../resources/Keno/img/icons-calendar.png');
const payIcon = require('../../../resources/Keno/img/pay.png');

const { base_url } = require("../../../constants/index.js")

var current_game_id = 0;
var openStatus = "closed";

const GameStat = () => {

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [isError, setIsError] = useState(true);
    const [currentGameLength, setCurrentGameLength] = useState(30000);
    const [fetchedData, setFetchedData] = useState([]);
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [currentGameId, setCurrentGameId] = useState("NONE");
    const [isBetOpen, setIsBetOpen] = useState(true);
    // const [numBets, setNumBets] = useState(0);
    // const [totalBets, setTotalBets] = useState(0);
    function get_current_game_length() {
      const url = `${base_url}getgamelength`;
      const res = fetch(url, {
          method: 'GET',
          headers: { "Content-Type": "application/json" },
          
        })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
                //  console.log(json);
                setCurrentGameLength(json.game_length);
             //  setFetchedData(json);
          
          });
    
        } else {
         // console.log("not fetched");
        }
      }).catch((err) => {
        // console.log("AXIOS ERROR: ", err);
         //     console.log("not fetched 2");   
          
      });
    }
    function get_current_game_length_spinner() {
      const url = `${base_url}spinner/all_tables/users/getgamelength`;
      const res = fetch(url, {
          method: 'GET',
          headers: { "Content-Type": "application/json" },
          
        })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
                //  console.log(json);
                setCurrentGameLength(json.game_length);
             //  setFetchedData(json);
          
          });
    
        } else {
         // console.log("not fetched");
        }
      }).catch((err) => {
        // console.log("AXIOS ERROR: ", err);
         //     console.log("not fetched 2");   
          
      });
    }
    function seconds_to_clock(duration) {
      if(duration < 0){
        duration = 0;
      }
        var time = duration/1000;
        // var hours = Math.floor(time / 3600);
        // time -= hours * 3600;
      
      var minutes = Math.floor(time / 60);
      time -= minutes * 60;
      
      var seconds = parseInt(time % 60, 10);
      
      //console.log(hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds));
      return  (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds); 
      
       }


    function count_total_bets() {
        const url = `${base_url}all_tables/users/counttotalbets`;
        const res = fetch(url, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({game_number: current_game_id})
          }).then(response => {
            if (response.ok) {
              response.json().then(json => {
                
                  // setTm(json);
                  // console.log(json);
                   setFetchedData(json);
              
              });
            } else {
              setIsError(true);
            }
          }).catch((err) => {
            setIsError(true);
                console.log("connection not working") ;
          });

       }
  
    function fetch_data_intro() {
    
        //----------------------------fetching open status and time remaining---------------------------
        // const url_open_status = `${base_url}all_tables/users/getopenstatus`;
        const url_open_status = `${base_url}getopenstatus`;
                    
        const res = fetch(url_open_status)
        .then(response => {
          if (response.ok) {
            response.json().then(json => {
              // console.log(json);
             if(json.open_status == 'closed'){
               setTimeRemaining(0);
                
                openStatus = "closed";
                setIsBetOpen(false);
              //  console.log("to fetch data intro");
                fetch_data_intro();
               
             }
             else{
               
               setTimeRemaining(json.time_remaining);
               //openStatus = "open";
              // setIsBetOpen(true);
              // console.log("to fetch data first");
               fetch_data_first();
             }
             
            // console.log("fetched");
            });
    
          } else {
           // console.log("not fetched");
           fetch_data_intro();
          }
        }).catch((err) => {
          // console.log("AXIOS ERROR: ", err);
           //     console.log("not fetched 2");   
           fetch_data_intro(); 
        });
    //---------------------------end of fetching open status and time remaining---------------------
    
     }
    
       //the timer to fetch data one
       function fetch_data_first() {
        
          //----------------------------fetching open status and time remaining---------------------------
          // const url_open_status = `${base_url}all_tables/users/getopenstatus`;
          const url_open_status = `${base_url}getopenstatus`;
                      
          const res = fetch(url_open_status)
          .then(response => {
            if (response.ok) {
              response.json().then(json => {
                // console.log(json);
               if(json.open_status == 'closed'){
                 setTimeRemaining(0);
                  // start spinner
               
                     
                    openStatus = "closed";
                    setIsBetOpen(false);
                     fetch_data_first();
                
                    
               }
               else{
                 setTimeRemaining(json.time_remaining);
                if(openStatus != "open"){
                    get_current_game_length();
                    setCurrentGameId(json.current_game_id);
                    current_game_id = json.current_game_id;
                    setIsBetOpen(true);
                    openStatus = "open";
                }
                setIsBetOpen(true);
                openStatus = "open";
                 fetch_data_first();
                 count_total_bets();
               }
               //console.log("to fetch data second");
               
              // console.log("fetched");
              });
    
            } else {
             // console.log("not fetched");
             fetch_data_first();
            }
          }).catch((err) => {
            // console.log("AXIOS ERROR: ", err);
             //     console.log("not fetched 2");   
             fetch_data_first(); 
          });
    //---------------------------end of fetching open status and time remaining---------------------
    
       }

useEffect(  () => {
    fetch_data_intro();
 
},[]);
   
    return (
  
        <div style = {{marginBottom: '-60px'}}>
        <div style = {{height: "150px", background: "#86A879", padding: "1.2rem 2rem"}}>  
        <span style = {{color: "#fff"}}><span style = {{color: "#dcdcdc"}}>Admin</span> / Current Game</span><br></br>
        <span style = {{color: "#fff"}}>Stats</span>
        </div>
        
        

    
      <div className = "admin_rep_game_stat" style = {{background: "#fff", 
      borderRadius: "10px"
      
      }}>
                        <div className = "admin_game_number" style = {{textAlign: "center"}}>
                              <span style = {{fontWeight: "600"}}>GAME</span>&nbsp; <span style = {{fontWeight: "600"}}>#{currentGameId}</span>  
                        </div>
                        <div style = {{display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div className = "admin_progress_container">
                              <div className="admin_progress admin_progress-bar-horizontal" >
                                    <div className = "admin_progress-bar"  aria-valuemin="0" aria-valuemax="100" 
                          
                                       style = {{ width : `${100 - Math.round(timeRemaining * 100/currentGameLength)}%` }}
                                      >

                                    </div>
                              </div>
                                  
                
                        </div>
                        <div className = "cashier_timer" style = {{color: "#000"}}>
                              {isBetOpen ? <span> <span style = {{fontWeight: "600"}}>BET CLOSES IN </span><span style = {{fontWeight: "600"}}>  {seconds_to_clock(timeRemaining)} </span></span>
                              : <span style = {{fontWeight: "600"}}>BET IS CLOSED</span>
                            }
                        </div>
                        </div>
      </div>

      <div className = "admin_count_bets" >
        <div className = "admin_count_bets_small" 
        style = {{  background: "#fff",  borderRadius: "10px", display: "flex", alignItems: "center", 
        justifyContent: "space-between" }}><span>{fetchedData[0] && fetchedData[0].total_bet_money != null ? fetchedData[0].total_bet_money : 0}&nbsp; Birr Current Bets</span>
        <span style = {{background: "violet" , padding: "20px", borderRadius: "50%"}}><FaCoins style = {{color: "#fff", width: "20px", height: "20px"}} /></span></div>
        <div className = "admin_count_bets_small" 
        style = {{  background: "#fff",  borderRadius: "10px", display: "flex", alignItems: "center",
        justifyContent: "space-between"}}><span>{fetchedData[0] && fetchedData[0].bet_count != null ? fetchedData[0].bet_count : 0}&nbsp; Number of Bets</span>
        <span style = {{background: "purple" , padding: "20px", borderRadius: "50%"}}><FaShoppingCart style = {{color: "#fff", width: "20px", height: "20px"}} /></span>
        </div>
      </div>
      
    </div>
    ); 

}
export default GameStat;